import { css } from "@emotion/react";

export const colourCss = {
    primary: css`
        color: #232a92;
    `,
    secondaryBright: css`
        color: #0043ff;
    `,
    accent: css`
        color: #fec601;
    `,
    secondaryDull: css`
        color: #4361ee;
    `,
    textPrimary: css`
        color: #16056b;
    `,
    textSecondary: css`
        color: #8c8c8c;
    `,
    textBlack: css`
        color: #2d2d2d;
    `,
    textSuccess: css`
        color: #06d6a0;
    `,
    textError: css`
        color: #f85a3e;
    `,
};

export const colours = {
    primary: "#232a92",
    secondaryBright: "#0043FF",
    secondaryBrightTransparent: "#A4B3D8",
    accent: "#FEC601",
    secondaryDull: "#4361EE",
    textPrimary: "#16056B",
    textSecondary: "#0A1A40",
    textSuccess: "#06D6A0",
    textError: "#F85A3E",
};

export const typography = {
    heading1: css`
        //styleName: Heading 1;
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
    `,
    heading2: css`
        //styleName: Heading 2;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
    `,
    heading3: css`
        //styleName: Heading 3;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
    `,
    heading4: css`
        //styleName: Heading 4;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
    `,
    heading5: css`
        //styleName: Heading 5;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
    `,
    heading6: css`
        //styleName: Heading 6;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
    `,
    mainContent: css`
        //styleName: Main Content;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        line-height: 22px;
        letter-spacing: 0em;
    `,
    subContent: css`
        //styleName: Sub Content;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0em;
    `,
    tinyContent: css`
        //styleName: Tiny Content;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0em;
    `,
    microContent: css`
        //styleName: Micro Content;
        font-family: Lato;
        font-size: 9px;
        font-style: normal;
        line-height: 13px;
        letter-spacing: 0em;
        color: #16056b;
    `,
};
